// Generates JWT Header
function generateEncodedJSONHeader() {
  var headerObj = new Object();
  headerObj.alg = "HS256";
  headerObj.typ = "JWT";
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(headerObj)));
}

// Generates JWT Signature
function generateHashSignature(encodedHeaderJSON, encodedPayLoadJSON, apiSecret) {
  var data = encodedHeaderJSON + "." + encodedPayLoadJSON;
  var hash = CryptoJS.HmacSHA256(data, apiSecret);
  return CryptoJS.enc.Base64.stringify(hash);
}

// Generates JWT Payload
function generateEncodedPayloadJSON(accountCredential, region) {
  var payLoadObj = new Object();
  payLoadObj.account_credential = accountCredential;
  payLoadObj.region = region;
  payLoadObj.type = "AuthTokenV2";
  payLoadObj.ts = new Date().getTime();
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(payLoadObj)));
}

function encodeURL(encodedSource){
  //Making Base64 URL Safe
  encodedSource = encodedSource.replace(/\+/g, '-');
  encodedSource = encodedSource.replace(/\//g, '_');
  return encodedSource;
}

// This JS method takes accountCredential,region and apiSecret to generate sample AuthTokenV2
function generateAuthTokenV2(accountCredential, region, apiSecret) {
  if (accountCredential == null || accountCredential == "" || region == null || region == "" || apiSecret== null || apiSecret== "") {
    throw "Mandatory input data is missing";
  }

  try {
    var encodedHeaderJSON = encodeURL(generateEncodedJSONHeader());
    var encodedPayLoadJSON = encodeURL(generateEncodedPayloadJSON(accountCredential, region));
    var encodedSignature = encodeURL(generateHashSignature(encodedHeaderJSON, encodedPayLoadJSON, apiSecret));
    return encodedHeaderJSON + "." + encodedPayLoadJSON + "." + encodedSignature;
  } catch (err) {
    var errorMsg = "Error While Generating AuthTokenV2 : " + err;
    throw errorMsg;
  }
}

window.generateAuthTokenV2 = generateAuthTokenV2;
